.PeopleBreakdown {
    nav {
        display: flex;
        justify-content: center;
        padding: 0.5em;
    }
    a {
        text-decoration: none !important;
    }
    .info, .search {
        margin-bottom: 1em;
    }
    .error-msg {
        margin-top: 1em;
    }
    .controls {
        display: flex;
        flex-direction: column;
        width: 100%;
        nav li {
            text-transform: capitalize;
        }
    }
    .MuiAccordionSummary-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .MuiAccordion-root.Mui-expanded {
        margin: 0;
    }
    .actions {
        display: flex;
        justify-content: space-around;
    }
    .MuiTableContainer-root {
        thead th {
            text-transform: capitalize;
        }
        .name-cell {
            display: flex;
            align-items: center;
            text-transform: capitalize;
        }
        .MuiSvgIcon-root {
            cursor: pointer;
        }
    }
    .sticky-column {
        position: sticky;
        left: 0;
        z-index: 1;
        background: white;
        padding-right: 0;
    }
    .details {
        @media only screen and (max-width: 768px) {
            max-height: 5em;
            width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.export-modal {
    width: calc(100vw - 4em);
    margin: auto;
    height: calc(100vh - 4em);
    overflow-y: auto;
    pre {
        padding: 1em;
        background-color: white;
        white-space: pre-wrap;
        div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    textarea {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

.name-in-details {
    text-transform: capitalize;
}

.shield-icon {
    width: 18px;
}
.sidebar-toggle {
    display: flex;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(50%);
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding: 0 0.2em;
    box-shadow: 1px 1px 5px 0px #000000;
    img {
        width: 35px;
        height: 35px;
    }
}

.MuiDrawer-paperAnchorLeft {
    max-width: 80%;
}

.sidebar {
    h6 {
        font-size: 1rem;
    }
    a {
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none;
        padding: 1em;
        > * {
          margin-right: 0.5em;  
        }
        &:hover {
            background-color: darkgray;
        }
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiToolbar-root {
  display: flex;
  justify-content: space-evenly;
  background-color: #d32f2f;
  h6 {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    font-size: 1em;
  }
  a {
    text-decoration: none !important;
    color: white;
    &.active {
      color: yellow;
    }
  }
}

.legend {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  > div {
      display: flex;
      align-items: center;
  }
}

.media-modal {
  li {
      height: auto !important;
  }
  li div {
      display: flex;
      justify-content: center;
  }
  img, video {
      @media only screen and (min-width: 768px) {
          max-width: 400px;
          max-height: 700px;
      }
      max-width: 100%;
  }
  .media-warning {
    font-weight: 900;
    display: block;
    > div {
      display: block;
      margin-bottom: 1em;
    }
  }
  hr {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

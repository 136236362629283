.media-image-container {
    position: relative;
    .blur-icon {
        cursor: pointer;
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        z-index: 1;
    }
    .blurred {
        filter: blur(15px)
    }
}